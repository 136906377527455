import * as R from 'ramda'
import { DOM } from 'rx-dom'
import { fromUnixTime, getUnixTime, subWeeks } from 'date-fns'

import { EntityRepositoryFilter } from '../../actions/entityRepository'
import { FilterSuggestion, Params } from '../../api/opoint-search-suggest.schemas'
import { Profile } from '../../components/types/profile'
import { Tag } from '../../components/types/tag'
import { MONTHS } from '../../constants'
import { SearchFilter } from '../../reducers/search'
import { getIntlTimeZone, handleErrors, isNumeric } from '../common'
import config from '../common/config'
import type {
  Filter,
  FiltersMap,
  MultipleSuggestions,
  MultipleSuggestionsOfType,
  SearchItem,
  SearchResult,
  Searchline,
  SuggestionType,
  TimePeriod,
} from '../flow'
import type { LocaleSuggestionLocale } from '../../components/types/locale'
import { SearchFilterKey } from '../../components/hooks/useSearchFilters'

export const filterTblistType = ['tblist', 'list']

export const filterDetailsSupportedTypes = ['site', 'geo', 'content', 'media', 'lang', ...filterTblistType]

export const allFilterTypes = ['tag', 'trash', 'profile', 'chart', 'timePeriod', ...filterDetailsSupportedTypes]

/**
 * Once filters is added, it should be inserted to the right location.
 * This Object defines an order in which filters should be sorted.
 *
 * Please make sure all values are bigger than 0.
 *
 * @type Object
 */
export const filtersOrder = {
  profile: 8,
  tag: 8,
  content: 7,
  media: 6,
  geo: 5,
  lang: 4,
  cov: 3,
  site: 2,
  timePeriod: 1,
}

// default searchD params
export const defaultParams: Params = {
  requestedarticles: 20,
  icon_resolution: 49,
  main: {
    header: 2,
    summary: 2,
    text: 2,
    quotes: 2,
    // @ts-expect-error: Incorrect schema
    colorbar: 1,
    // @ts-expect-error: Incorrect schema
    matches: 1,
    shortheaderlength: 200,
    shortsummarylength: 1932,
    shortbodylength: -1932,
  },
  identical: { inherit: true },
  colorbaropt: { pixels: 0, max_color: 8 },
  groupidentical: true,
  // @ts-expect-error: Incorrect schema
  use_short_links: 1,
  picture_types: '2,4',
  include_equalgroup_id: 1,
  mark_matches_url: 1,
  sort_oldest_first: false,
  watch_id: -1,
  different_colors: 8,
  allsubject: '0',
}
// default searchD params
export const defaultStatParams = {
  ...defaultParams,
  requestedarticles: 500,
  acceptedcacheage: -1,
  main: {
    header: 0,
    summary: 0,
    text: 0,
    quotes: 0,
    colorbar: 0,
  },
  include_equalgroup_id: 0,
  picture_types: '',
  groupidentical: 0,
  mark_matches_url: 0,
  watch_id: 0,
  identical: undefined,
}
export const NUMBER_OF_SUGGESTIONS = 10

export const tagProfileTypeList = ['tag', '-tag', 'profile', '-profile']

export function filterIdIgnoreInverted({ id, type }: Filter): string {
  const filterType = type.startsWith('-') ? type.replace('-', '') : type

  return `${filterType}:${id}`
}

export function filterId({ id, type }: Filter): string {
  return `${type}:${id}`
}

export const eqFilters = R.eqBy(filterId)
// @ts-expect-error: Muted so we could enable TS strict mode
export const cutFiltersTypeMinus = R.when(R.compose(R.equals('-'), R.head), R.slice(1, Infinity))

export const filterIsTag = R.propEq('type', 'tag')
export const filterIsTrashTag = R.propEq('type', 'trash')
export const filterIsProfile = R.propEq('type', 'profile')
export const filterIsChart = R.propEq('type', 'chart')
export const filterIsTimePeriod = R.propEq('type', 'timePeriod')
export const filterIsTBList = R.compose(
  /* eslint-disable-next-line no-underscore-dangle */
  R.contains(R.__, filterTblistType),
  // @ts-expect-error: Muted so we could enable TS strict mode
  R.prop('type'),
)
// @ts-expect-error: Muted so we could enable TS strict mode
export const filterIsDetailsSupported = R.compose(
  /* eslint-disable-next-line no-underscore-dangle */
  R.contains(R.__, filterDetailsSupportedTypes),
  cutFiltersTypeMinus,
  R.prop('type'),
)

export const filtersToFiltersMap: (arr: Array<Filter>) => FiltersMap = R.indexBy(filterId)

/**
 * Given a list of suggestions, it returns a string that should be sent to a suggestion server.
 * @param filters
 * @returns {string}
 */
export function filtersToSuggestionServerString(filters?: Array<Filter>) {
  // @ts-expect-error: Muted so we could enable TS strict mode
  return R.equals({}, filters) || !filters
    ? ''
    : R.values(filters)
        // @ts-expect-error: Muted so we could enable TS strict mode
        ?.map(({ id, type }) => `${type}:${id}`)
        .join(',')
}

/**
 * Given searchterm and filters, it sends a request to a suggestion server and returns a promise.
 * @param searchterm
 * @param filters
 * @returns {Promise}
 */
const getSuggestionsFromSuggestionServer = R.curry(
  async (
    type: string,
    searchterm: string,
    filters,
    suggestionLocale,
    defaultSearchScope: Array<Filter>,
    numberOfSuggestions: number,
    accessParameter: number,
  ): Promise<MultipleSuggestionsOfType | Array<FilterSuggestion>> => {
    const searchFiltersWithDefaultFilters = {
      ...filters,
      ...filtersToFiltersMap(defaultSearchScope),
    }

    const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
      // Trainling slash at the end of url is been required.
      // That because without trailing slash, loads a file.
      // If search query ends on `.js`, cross-domain request will be handled.
      // This will lead to error exception: `XMLHttpRequest has been blocked by CORS policy`.
      url: config.url.api(
        `/suggest/${suggestionLocale}:UTC+1/${type}/${
          numberOfSuggestions || NUMBER_OF_SUGGESTIONS
        }/0/0/2147483647:${accessParameter}/${filtersToSuggestionServerString(searchFiltersWithDefaultFilters)}/${
          encodeURIComponent(searchterm) || '%20'
        }`,
      ),
    })

    return DOM.ajax(requestHeaders)
      .toPromise()
      .then(({ response }) => response)
  },
)

const getSuggestions = R.curry(
  (
    type: string,
    searchterm: string,
    filters: Filter[] = [],
    { suggestionLocale = 'en_GB_1' } = {},
    defaultSearchScope: any,
    numberOfSuggestions: number,
    accessParameter: number,
  ) => {
    const tbList = getTbListSuggestionFromExpression(searchterm)
    // @ts-expect-error: Muted so we could enable TS strict mode
    if (tbList !== null && type === 0) {
      return Promise.resolve({ results: [tbList] })
    }

    return getSuggestionsFromSuggestionServer(
      type,
      searchterm,
      filters,
      suggestionLocale,
      defaultSearchScope,
      numberOfSuggestions,
      accessParameter,
    )
  },
)

// @ts-expect-error: Muted so we could enable TS strict mode
export const getSimpleSuggestions = getSuggestions(0)
export const getMultipleSuggestions = getSuggestions('multiple')

export async function getSuggestionDetail(
  id: string,
  type: string,
  locale: LocaleSuggestionLocale,
): Promise<EntityRepositoryFilter[]> {
  const timeZone = getIntlTimeZone()

  const requestHeaders = R.merge(await config.request.getRequestHeaders(), {
    url: config.url.api(`/suggest/${locale}:${timeZone}/getids/${type}:${id}`),
  })

  return DOM.ajax(requestHeaders)
    .toPromise()
    .then(({ response }) => response)
}

// API documentation -  https://api-docs.opoint.com/references/suggestion-server#from-ids-to-names
export const getIds = async ({ id, type }: { id: number; type: string }) => {
  const requestHeaders = await config.request.getRequestHeaders()

  return fetch(config.url.api(`/suggest//getids/${type}:${id}`), {
    ...requestHeaders,
  })
    .then(handleErrors)
    .then((response) => response.json())
}

export const entityToSearchItems = (id: number, type: 'tag' | 'profile'): Array<SearchItem> => [
  {
    searchline: {
      filters: [{ type, id: `${id}` }],
    },
    linemode: 'R',
  },
]

export const searchDataToExpression = R.curry(
  (searchData): Array<SearchItem> => [
    {
      searchline: searchData,
      linemode: 'R',
    },
  ],
)

export const tagToSearchItems = (id: number) => entityToSearchItems(id, 'tag')

export const profileToSearchItems = (id: number) => entityToSearchItems(id, 'profile')

export const processSearch = R.compose(normalizeTimestamps, processSearchMeta)

// TODO add flow type for searchd params
export async function search(
  expressions: Array<SearchItem>,
  searchParams: any = {},
  params: any = {},
  locale = 'en-GB',
  useDefaultStatParams = false,
): Promise<any> {
  const requestHeaders = R.evolve({
    headers: {
      'accept-language': R.always(locale),
    },
  })(await config.request.getRequestHeaders())

  // If the user is searching for an article by ID, we don't include filters in the search request.
  // Currently, the BE can't find an article based on the given filters.
  // https://forum.opoint.com/t/search-by-article-id-fails-when-profile-filter-is-applied/1834
  if (expressions?.length) {
    expressions.forEach(({ searchline }) => {
      if (searchline?.searchterm?.startsWith('article:')) {
        searchline.filters = searchline.filters.filter(({ type }) => type !== SearchFilterKey.PROFILES)
      }
    })
  }

  const finalDefaultParams = useDefaultStatParams ? defaultStatParams : defaultParams

  return fetch(config.url.api('/search/'), {
    ...requestHeaders,
    method: 'POST',
    body: JSON.stringify({
      params: { ...finalDefaultParams, ...searchParams },
      expressions,
      ...params,
    }),
  })
    .then(handleErrors)
    .then((respond) => respond.json())
    .then(processSearch)
}

export async function getSingleArticle(
  params: any = {},
  locale = 'en-GB',
  expressions?: Array<SearchItem>,
): Promise<any> {
  const requestHeaders = R.evolve({
    headers: {
      'accept-language': R.always(locale),
    },
  })(await config.request.getRequestHeaders())

  return fetch(config.url.api('/search/'), {
    ...requestHeaders,
    method: 'POST',
    body: JSON.stringify({
      params: { ...defaultParams, ...params },
      ...(expressions && { expressions }),
    }),
  })
    .then(handleErrors)
    .then((respond) => respond.json())
    .then(processSearch)
}

export async function searchStatistics(
  expressions: Array<SearchItem>,
  searchParams: any = {},
  params: any = {},
  locale = 'en-GB',
): Promise<any> {
  const body = JSON.stringify({
    params: R.mergeAll([defaultStatParams, params, searchParams]),
    expressions,
  })
  const requestHeaders = R.evolve({
    headers: {
      'accept-language': R.always(locale),
    },
  })(await config.request.getRequestHeaders())

  return fetch(config.url.api('/search/'), {
    ...requestHeaders,
    method: 'POST',
    body,
  })
    .then(handleErrors)
    .then((respond) => respond.json())
    .then(processSearch)
}

export async function verifyProfile(expressions: Array<SearchItem>): Promise<SearchResult> {
  // We do not need to check if filters are entered correctly
  // also backend returns buggy things if we do
  const expressionsWithoutFilters = expressions?.map((exp) => R.assocPath(['searchline', 'filters'], [], exp))

  return fetch(config.url.api('/search/'), {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify({
      params: { requestedarticles: 1 },
      expressions: expressionsWithoutFilters,
    }),
  })
    .then(handleErrors)
    .then((respond) => respond.json())
}

export async function searchTimestamps(
  { stimestamps, tagId }: { stimestamps: Array<number>; tagId: number },
  searchParams: any = {},
  params: any = {},
): Promise<any> {
  // TODO flow @dmytro
  return fetch(config.url.api(`/search/tag/${tagId}/reports/`), {
    ...(await config.request.getRequestHeaders()),
    method: 'POST',
    body: JSON.stringify({
      params: { ...defaultParams, ...searchParams },
      stimestamps,
      ...params,
    }),
  })
    .then(handleErrors)
    .then((respond) => respond.json())
    .then(processSearchMeta)
}

export function getSuggestionOfType(type: SuggestionType, data: MultipleSuggestions) {
  // @ts-expect-error: Muted so we could enable TS strict mode
  return data[type]?.suggest
}

/**
 * Returns true if filters is inverted, false otherwise
 * @param filter
 * @returns {boolean}
 */
export function filterIsInverted(filter: Filter) {
  return filter && filter.type && filter.type[0] === '-'
}

export const invertFilter = (filter: Filter) =>
  filterIsInverted(filter)
    ? R.assoc('type', filter.type.substring(1), filter)
    : R.assoc('type', `-${filter.type}`, filter)

/**
 * Function takes object fo filters and return updated object with new filter added.
 *
 * If type of new filter is defined to be presetn only once in filters, then other
 * instances of that filter type will be removed
 * @param {String} filterId - id of new filter, eg. profile:1314
 * @param {Filter} filterToBeAdded - filter object to be added to filters
 * @param {FiltersMap} filters - object of all active filters
 * @return {FiltersMap} - updated filters
 */
export function addOneOfKind(filterId: string, filterToBeAdded: Filter, filters: FiltersMap): FiltersMap {
  // array of filter types which should be present only once in filters
  const ONLY_ONE = ['timePeriod']

  const filterType = filterToBeAdded.type
  let purgedFilters = filters

  if (ONLY_ONE.includes(filterType)) {
    purgedFilters = R.filter((f) => f.type !== filterType, filters)
  }

  return R.assoc(filterId, filterToBeAdded, purgedFilters)
}

/**
 * Following method takes a filter from the suggestion server and
 * a string as parameters.
 *
 * Based on the filter's matched property, it modifies a string so that
 * unmatched part of the string is kept and user can use this string
 * to filter again.
 *
 * Example:
 *
 * string - `dagbladet vg`
 * if the filter `vg` is selected:
 *   returns `dagbladet`
 *
 * @param {Object} filter - suggestion server's filter
 * @param {String} string - string to be modified
 * @returns {String} modified string
 */
export const getUnmatchedString = R.curry((filter, string) => {
  if (filter.type === 'timePeriod') {
    return string
  }

  const tokens = string.split(' ')

  // eslint-disable-next-line no-prototype-builtins
  if (filter.hasOwnProperty('match') && filter.match !== '') {
    const matches = filter.match.split('')

    let newExpression = ''
    for (let i = 0; i < tokens.length; i++) {
      if (matches[i] === '0') {
        newExpression += ` ${tokens[i]}`
      }
    }

    return newExpression.replace(/^\s+|\s+$/, '')
  }

  return string
})

/**
 * If filter is inverted (type begins with '-')
 * returns type name without '-'
 * Used when sorting filters in the search field
 * and in op-search-input plugin
 *
 * @param {Object} filter whose type should be normalized
 * @returns {String} normalized type name
 */
export function normalizedType(filter: Filter) {
  if (filter && filter.type) {
    if (tagProfileTypeList.includes(filter.type)) {
      return 'filter-tag'
    }

    return filter.type[0] === '-' ? filter.type.substring(1) : filter.type
  }

  return 'profile'
}

/**
 * Returns true if two filters are of a same type. In case one of them is inverted it
 * treats it as a same type.
 * @param filter1
 * @param filter2
 */
// @ts-expect-error: Muted so we could enable TS strict mode
export const filtersTypeEq: (Filter, Filter) => boolean = R.eqBy(normalizedType)

export function compareFilters(x: Filter, y: Filter) {
  const xType = normalizedType(x)
  const yType = normalizedType(y)
  const firstOrder = filtersOrder[xType] || 1
  const secondOrder = filtersOrder[yType] || 1

  return firstOrder !== secondOrder ? firstOrder - secondOrder : parseInt(String(x.id), 10) - parseInt(String(y.id), 10)
}

/**
 *
 * @param String expression where to look for TB List
 * @returns null when no TB List expression in format
 * "list:999" is found
 * @return Object filter with TB list ID
 */
function getTbListSuggestionFromExpression(expr: string) {
  // TODO needs tests
  // Add TB list to suggestions if there is one in expression
  if (/list:\d+/.test(expr)) {
    const wordCount = expr.split(/\s+/).length
    const match = Array(wordCount).join('0')

    return {
      // @ts-expect-error: Muted so we could enable TS strict mode
      id: expr.match(/list:(.[0-9]*)/)[1],
      type: 'list',
      typeName: 'TB list', // TODO needs translation
      // @ts-expect-error: Muted so we could enable TS strict mode
      name: `list:${expr.match(/list:(.[0-9]*)/)[1]}`,
      match: `${match}1`,
    }
  }

  return null
}

// TODO: write test
function processSearchMeta(response) {
  // converts timestamp 0 to actual time, otherwise start of epoch will be displayed
  const convertToNow = (timestamp) => timestamp || getUnixTime(new Date())

  return R.evolve({
    searchresult: {
      range_start: (t) => convertToNow(t),
      range_end: (t) => convertToNow(t),
    },
  })(response)
}

// TODO: write test
type TimePeriodParams = {
  newest?: number
  oldest?: number
}

/**
 * Process timePeriod filter to format usable in search api call
 */

export function parseTimeFilterToTimeStamps(filterId: string, isStatistics?: boolean): TimePeriodParams {
  const dates: TimePeriodParams = {}

  function getStartOfToday() {
    const now = new Date()

    return new Date(now.getFullYear(), now.getMonth(), now.getDate())
  }

  function getStartOfYear() {
    const now = new Date()

    return new Date(now.getFullYear(), 0, 1)
  }

  function getStartOfMonth() {
    const now = new Date()

    return new Date(now.getFullYear(), now.getMonth(), 1)
  }

  function getStartOfWeek() {
    const now = new Date()

    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1)
  }
  // TODO - write getStartOfQuarter & getEndOfPreviousQuarter using modulo & functional approach
  /**
   * This functions returns start of quarter in which is user currently in.
   * Alternatively, it returns previous quarter
   * if supplied with optional parameter isPrevQuarter
   * @param isPrevQuarter - if true, it returns previous quarter
   *   instead of quarter that user is currently in
   * @returns object in the format of {oldest: Integer}
   */
  function getStartOfQuarter(isPrevQuarter = false) {
    const now = new Date()
    const month = isPrevQuarter ? now.getMonth() - 3 : now.getMonth()
    let date
    switch (month) {
      case MONTHS.JANUARY:
      case MONTHS.FEBRUARY:
      case MONTHS.MARCH:
        date = new Date(now.getFullYear(), MONTHS.JANUARY, 1)
        break
      case MONTHS.APRIL:
      case MONTHS.MAY:
      case MONTHS.JUNE:
        date = new Date(now.getFullYear(), MONTHS.APRIL, 1)
        break
      case MONTHS.JULY:
      case MONTHS.AUGUST:
      case MONTHS.SEPTEMBER:
        date = new Date(now.getFullYear(), MONTHS.JULY, 1)
        break
      // In case that in January (0) months we substract 3
      // we get negative number even though we should get 4th quartal
      default:
        const year = isPrevQuarter ? now.getFullYear() - 1 : now.getFullYear()
        date = new Date(year, MONTHS.OCTOBER, 1)
    }

    return date
  }
  /**
   * This functions returns end of previous quarter
   * @returns object in the format of {newest: Integer}
   */
  function getEndOfPreviousQuarter() {
    const now = new Date()
    const month = now.getMonth()
    let date
    switch (month) {
      case MONTHS.JANUARY:
      case MONTHS.FEBRUARY:
      case MONTHS.MARCH:
        date = new Date(now.getFullYear(), MONTHS.JANUARY, 0)
        break
      case MONTHS.APRIL:
      case MONTHS.MAY:
      case MONTHS.JUNE:
        date = new Date(now.getFullYear(), MONTHS.APRIL, 0)
        break
      case MONTHS.JULY:
      case MONTHS.AUGUST:
      case MONTHS.SEPTEMBER:
        date = new Date(now.getFullYear(), MONTHS.JULY, 0)
        break
      case MONTHS.OCTOBER:
      case MONTHS.NOVEMBER:
      case MONTHS.DECEMBER:
        date = new Date(now.getFullYear(), MONTHS.OCTOBER, 0)
        break
      default:
    }

    return date
  }

  /**
   * Calculates the start and end dates of the previous month.
   *
   * @returns {Object} An object containing the start and end dates of the previous month.
   */
  function getPreviousMonthDates() {
    const currentDate = new Date()
    const firstDayCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)

    const endPreviousMonth = new Date(firstDayCurrentMonth.getTime() - 1)
    const startPreviousMonth = new Date(endPreviousMonth.getFullYear(), endPreviousMonth.getMonth(), 1)

    return {
      startPreviousMonth,
      endPreviousMonth,
    }
  }

  const now = isStatistics ? Math.floor(+new Date() / 1000) : undefined
  // we're searching within the last day
  switch (filterId) {
    case 'D':
      // day
      dates.newest = now
      dates.oldest = Math.floor(getStartOfToday().getTime() / 1000)
      break
    case 'Y':
      // this year
      dates.newest = now
      dates.oldest = Math.floor(getStartOfYear().getTime() / 1000)
      break
    case 'M':
      // this month
      dates.newest = now
      dates.oldest = Math.floor(getStartOfMonth().getTime() / 1000)
      break
    case 'W':
      // this week
      dates.newest = now
      dates.oldest = Math.floor(getStartOfWeek().getTime() / 1000)
      break
    case 'D-1,D':
      // yesterday
      dates.newest = Math.floor(getStartOfToday().getTime() / 1000)
      dates.oldest = Math.floor(dates.newest - 24 * 3600)
      break
    case 'd-1':
      // last 24 hours
      dates.newest = now
      dates.oldest = Math.floor(new Date().getTime() / 1000 - 24 * 3600)
      break
    case 'H-12':
      // last 12 hours
      dates.newest = now
      dates.oldest = Math.floor(new Date().getTime() / 1000 - 12 * 3600)
      break
    case 'W-1,W':
      // previous week
      dates.newest = Math.floor(getStartOfWeek().getTime() / 1000)
      dates.oldest = Math.floor(dates.newest - 24 * 3600 * 7)
      break
    case 'M-1,M':
      // previous month
      const { startPreviousMonth, endPreviousMonth } = getPreviousMonthDates()

      dates.newest = Math.floor(endPreviousMonth.getTime() / 1000)
      dates.oldest = Math.floor(startPreviousMonth.getTime() / 1000)
      break
    case 'Q-1,Q':
      // previous quarter
      dates.newest = Math.floor((getEndOfPreviousQuarter().getTime() + 86400000) / 1000)
      dates.oldest = Math.floor(getStartOfQuarter(true).getTime() / 1000)
      break
    case 'Q':
      // this quarter
      dates.newest = now
      dates.oldest = Math.floor(getStartOfQuarter().getTime() / 1000)
      break
    case 'd-30':
      // last 30 days
      dates.newest = now
      dates.oldest = Math.floor(getStartOfToday().getTime() / 1000 - 24 * 3600 * 30)
      break
    case 'd-7':
      // last 7 days
      dates.newest = now
      dates.oldest = Math.floor(getStartOfToday().getTime() / 1000 - 24 * 3600 * 7)
      break
    default:
      const d = filterId.split('-')
      if (d.length === 4) {
        if (d[1] !== 'undefined') {
          dates.oldest = -1 * parseInt(d[1], 10)
        }
        if (d[3] !== 'undefined') {
          dates.newest = -1 * parseInt(d[3], 10)
        }
      } else if (d.length === 3) {
        if (d[1] !== 'undefined') {
          dates.oldest = -1 * parseInt(d[1], 10)
        }
        if (d[2] !== 'undefined') {
          dates.newest = parseInt(d[2], 10)
        }
      } else {
        if (d[0] !== 'undefined') {
          dates.oldest = parseInt(d[0], 10)
        }
        if (d[1] !== 'undefined') {
          dates.newest = parseInt(d[1], 10)
        }
      }
      break
  }

  return dates
}

// Decide whether or not filter can be inverted
export function isNotInvertable(filter: Filter): boolean {
  return filter.type === 'trash' || filter.type === 'timePeriod'
}

export function normalizeTimestamps(response) {
  let {
    range_end: rangeEnd,
    range_start: rangeStart,
    last_timestamp: lastTimestamp,
    first_timestamp: firstTimestamp,
  } = response.searchresult

  // normalize timestamps, so millisecond are always used instead of seconds
  rangeStart *= 1000
  rangeEnd *= 1000
  lastTimestamp *= 1000
  firstTimestamp *= 1000

  return R.evolve({
    searchresult: R.always({
      // might be equal to number of documents,
      // in which case all requested documents were delivered
      // or it might be bigger number which means not all requested
      // were delivered and time range selector should be shown
      rangeStart, // requested range start
      rangeEnd, // requested range end - might be 0 which means now
      lastTimestamp, // oldest - delivered range start
      firstTimestamp, // newest
      ...R.omit(['range_end', 'range_start', 'last_timestamp', 'first_timestamp'], response.searchresult),
    }),
  })(response)
}

export function firstSearchFilter(searchItems: Array<SearchItem>): Filter | false {
  if (!Array.isArray(searchItems)) {
    return false
  }

  if (searchItems.length !== 1) {
    // we search for more than one profileline
    return false
  }

  let searchline = searchItems[0]

  if (searchline.linemode !== 'R') {
    return false
  }
  // @ts-expect-error: Muted so we could enable TS strict mode
  searchline = searchline.searchline

  // @ts-expect-error: Muted so we could enable TS strict mode
  if (searchline.searchterm === undefined) {
    return false
  }

  // @ts-expect-error: Muted so we could enable TS strict mode
  if (searchline?.searchterm?.trim().length > 0) {
    // if we have a searchterm => we don't search for a profile only,
    // but we search within a profile
    return false
  }

  // @ts-expect-error: Muted so we could enable TS strict mode
  const timePeriodFiltered = searchline.filters.filter((filter) => filter.type !== 'timePeriod')
  // if there is more than one filters, we don't search for just one profile
  if (timePeriodFiltered.length !== 1) {
    return false
  }

  // @ts-expect-error: Muted so we could enable TS strict mode
  return R.head(timePeriodFiltered)
}

// This functions returns false if search is not profile search and
// profile id in case it is profile search
// export function isProfileSearch (searchItems: Array<SearchItem>): boolean | number {
export function getSearchIdByType(searchItems: Array<SearchItem>, type: 'profile' | 'tag'): false | number {
  const filter = firstSearchFilter(searchItems)
  if (filter && filter.type === type) {
    // @ts-expect-error: Muted so we could enable TS strict mode
    return parseInt(filter.id, 10)
  }

  return false
}

export const isProfileSearch = R.partialRight(getSearchIdByType, ['profile'])
export const isTagSearch = R.partialRight(getSearchIdByType, ['tag'])

/**
 * Decides whether a searchline can be saved as a profile
 * @param searchline
 * @returns {*|boolean}
 */
export function canSaveSearchlineAsProfile(searchline: Searchline): boolean {
  return (
    R.none(filterIsTBList, searchline.filters) &&
    !isProfileSearch([
      {
        searchline,
        linemode: 'R',
      },
    ])
  )
}

export function getFilterProfiles(profiles: Array<Profile>, searchterm: string) {
  const search = searchterm ? searchterm.toLowerCase() : ''

  // @ts-expect-error: Muted so we could enable TS strict mode
  return profiles.reduce((acc, profile) => {
    if (acc.length >= 10) {
      return acc
    }
    if (profile.name.toLowerCase().indexOf(search) !== -1) {
      return R.append(profile, acc)
    }

    return acc
  }, [])
}

export function getFilterTags(tags: Array<Tag>, searchterm: string) {
  const search = searchterm ? searchterm.toLowerCase() : ''

  // @ts-expect-error: Muted so we could enable TS strict mode
  return tags.reduce((acc, tag) => {
    if (acc.length >= 10) {
      return acc
    }
    if (tag.name.toLowerCase().indexOf(search) !== -1) {
      return R.append(tag, acc)
    }

    return acc
  }, [])
}

export const isSearchInputNotEmpty = (searchline: Searchline) => !R.isEmpty(searchline.filters) || searchline.searchterm

export const checkIfSuggestedFilterInverted = (filterIdString: string, searchFilters: SearchFilter): string => {
  const invertedFilterId = `-${filterIdString}`
  // @ts-expect-error: Muted so we could enable TS strict mode
  const doesFiltersArrayContainsTheInvertedFilter = R.find(R.equals(invertedFilterId), R.keys(searchFilters))

  return doesFiltersArrayContainsTheInvertedFilter ? invertedFilterId : filterIdString
}

/*
  Parse unix start and end timestamp from id in format {START}-{END}
*/
export function getStartAndEndFromId(timePeriod: { id: string }): TimePeriod {
  const [startDate, endDate] = R.map((unixTime) => fromUnixTime(+unixTime), R.split('-', R.prop('id', timePeriod)))

  return { startDate, endDate }
}

export function defaultStartEndDates(): TimePeriod {
  return {
    startDate: subWeeks(new Date(), 1),
    endDate: new Date(),
  }
}

export function isFilterCustomTimePeriod(filterId: string) {
  const [first, last] = filterId.split('-')

  return first && last && isNumeric(first) && isNumeric(last)
}

type TranslateFieldType =
  | 'header'
  | 'short_header'
  | 'summary'
  | 'short_summary'
  | 'body'
  | 'short_body'
  | 'caption'
  | 'articlemedia.text'
  | 'quotes'
  | 'colorbar'
  | 'translate_headline_always'
  | 'translate_summary_always'

export const getTranslateFieldsAsBinary = (fields: Array<TranslateFieldType>): number => {
  return R.uniq(fields).reduce<number>((acc, val) => {
    switch (val) {
      case 'header':
        return acc | 1
      case 'short_header':
        return acc | 2
      case 'summary':
        return acc | 4
      case 'short_summary':
        return acc | 8
      case 'body':
        return acc | 16
      case 'short_body':
        return acc | 32
      case 'caption':
        return acc | 64
      case 'articlemedia.text':
        return acc | 128
      case 'quotes':
        return acc | 256
      case 'colorbar':
        return acc | 512
      case 'translate_headline_always':
        return acc | 1024
      case 'translate_summary_always':
        return acc | 2048
    }
  }, 0)
}
