import { cn } from '@opoint/infomedia-storybook'
import { HTMLAttributes } from 'react'

type ActionBarProps = {
  id?: string
} & HTMLAttributes<HTMLDivElement>

const ActionBar = ({ id, children, className }: ActionBarProps) => (
  <div className={cn('border-b border-grey.6', className)}>
    <div id={id} className="flex h-[3.75rem] justify-between bg-grey.8 p-2.5">
      {children}
    </div>
  </div>
)

type ActionBarLeftProps = HTMLAttributes<HTMLDivElement>

export const ActionBarLeft = ({ children, className, ...props }: ActionBarLeftProps) => (
  <div className={cn('z-10 flex h-11', className)} {...props}>
    {children}
  </div>
)

export default ActionBar
