/* eslint-disable react/display-name */
import { Checkmark24Regular, Dismiss24Regular, Search24Regular } from '@fluentui/react-icons'
import { Button, cn } from '@opoint/infomedia-storybook'
import { useT } from '@transifex/react'
import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  MouseEvent,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useDebounceCallback } from 'usehooks-ts'
import { orderedSuggestions } from '../../../helpers/common'
import { getContactFilterId } from '../../../helpers/contacts'
import { Filter, SelectedCompanySentimentEntity } from '../../../opoint/flow'
import { getUnmatchedString } from '../../../opoint/search'
import AutocompleteItem from '../../../opoint/search/AutocompleteItem'
import { getFoldersTree } from '../../../selectors/foldersSelectors'
import ContactsFilter from '../../contacts/ContactsFilter'
import ContactSuggestion from '../../contacts/ContactsInput/ContactSuggestion'
import { useAppSelector } from '../../hooks/useAppSelector'
import SuggestionItem from '../../search/SuggestionItem'
import { CommonRecipientFilter, ContactFilter } from '../../types/contact'
import { Profile } from '../../types/profile'
import { LanguageOption } from '../../types/settings'
import { Tag } from '../../types/tag'

import { FilterSuggestion } from '../../../api/opoint-search-suggest.schemas'
import useEventListener from '../../hooks/useEventListener'
import DefaultSuggestion from './DefaultSuggestion'
import {
  FilterComponent as DefaultFilterComponent,
  ProfileEditorFilterComponent,
  SearchFilterComponent,
} from './FilterComponent'
import FilterSeparator, { Separator } from './FilterSeparator'
import GroupedSuggestion from './GroupedSuggestion'
import styles from './style.module.scss'

type AutocompleteFilter =
  | Filter
  | ContactFilter
  | CommonRecipientFilter
  | Tag
  | LanguageOption
  | SelectedCompanySentimentEntity

type AutocompleteSuggestion = Profile | Tag | ContactFilter | CommonRecipientFilter | FilterSuggestion | LanguageOption

type SuggestionComponent =
  | typeof AutocompleteItem
  | typeof DefaultSuggestion
  | typeof ContactSuggestion
  | typeof SuggestionItem

//TODO: these FilterComponents only handle filters of type Filter, not the other AutocompleteFilter types
type FilterComponent =
  | typeof DefaultFilterComponent
  | typeof SearchFilterComponent
  | typeof ContactsFilter
  | typeof ProfileEditorFilterComponent

//TODO: could we make this generic? or use filterComponent / suggestionComponent as children prop?
export type AutocompleteFieldProps = {
  id?: string
  alwaysShowClearAll?: boolean
  autoSuggestion?: boolean
  clearAllHandler: (filter?: AutocompleteFilter[]) => void
  /**
   * Only triggered by pressing '-' on keyboard
   * */
  excludeHandler?: (filter: AutocompleteFilter) => void
  filterComponent: FilterComponent
  filters: AutocompleteFilter[]
  hasNoBorder?: boolean
  showHint?: boolean
  parentId?: number
  /**
   * Only triggered by pressing '+' on keyboard
   * */
  includeHandler?: (filter: AutocompleteFilter) => void
  onFilterAdded?: (suggestion: ContactFilter | CommonRecipientFilter) => void
  onFilterToggled: (suggestion: ContactFilter | CommonRecipientFilter) => void // Adds or removes, depending on if already an active filter
  onFocusHandler?: () => void
  onSearchTermChanged: (text?: string) => void
  placeholder: string
  removeFilter: (filter: ContactFilter | CommonRecipientFilter) => void
  searchterm: string
  selectAllIcon?: boolean
  showInlineSuggestions?: boolean
  sortingFunction?: (a: Filter, b: Filter) => number
  suggestionComponent: SuggestionComponent
  suggestions: AutocompleteSuggestion[]
  tagSeparator?: boolean
  triggerSearch?: () => void
  typeComparator?: (sortedFilter: Filter, filter: Filter) => boolean
  groupByFolders?: boolean
  error?: string
  maxHeight?: boolean
}

const INITIAL_OFFSET = 3
const PADDING = 12
const TEXTAREA_HEIGHT = 40

const AutocompleteField = forwardRef<HTMLDivElement, AutocompleteFieldProps>(
  (
    {
      id,
      filters,
      tagSeparator = true,
      showInlineSuggestions = true,
      hasNoBorder = false,
      filterComponent: FilterComponent,
      suggestionComponent: SuggestionComponent,
      error,
      maxHeight,
      placeholder,
      showHint,
      ...props
    },
    ref,
  ) => {
    const t = useT()
    const [activeSuggestion, setActiveSuggestion] = useState<number | null>()
    const [isFocused, setIsFocused] = useState<boolean>(false)
    const [isSuggestionsListVisible, setIsSuggestionsListVisible] = useState<boolean>(false)

    const [localSearchTerm, setLocalSearchTerm] = useState(props.searchterm ?? '')

    const textareaRef = useRef<HTMLTextAreaElement>()
    const containerRef = useRef<HTMLDivElement>()
    const suggestionsWrapperRef = useRef<HTMLDivElement>()
    const suggestionsRef = useRef<HTMLUListElement>()

    const filterRefs = useRef<HTMLLIElement[]>([])

    const textAreaHeight = textareaRef.current
      ? textareaRef.current.getBoundingClientRect().height + textareaRef.current.offsetTop
      : TEXTAREA_HEIGHT

    const folders = useAppSelector(getFoldersTree)
    const newSuggestions = useMemo(() => {
      return props.groupByFolders
        ? orderedSuggestions(folders, props.suggestions as (Profile | Tag)[])
        : props.suggestions
    }, [folders, props.groupByFolders, props.suggestions])

    const getFilterId = (filter: AutocompleteFilter | AutocompleteSuggestion) =>
      'id' in filter ? filter.id : getContactFilterId(filter)

    const isSuggestionSelected = (suggestion: AutocompleteSuggestion) =>
      filters.some((filter) => getFilterId(filter) === getFilterId(suggestion))

    // Adjust the position of the text area to account for the pills
    const positionTextarea = useCallback(() => {
      if (!textareaRef.current || !filterRefs.current) {
        return
      }

      // Find the position of the last non-null element
      const index = filterRefs.current.reduceRight((lastIndex, filter, idx) => {
        return lastIndex === -1 && filter !== null ? idx : lastIndex
      }, -1)

      if (filters.length > 0 && index !== -1) {
        const lastElement = filterRefs.current[index]
        const offset = lastElement?.offsetTop - INITIAL_OFFSET

        textareaRef.current.style.top = `${offset > 0 ? offset : 0}px`
        textareaRef.current.style.textIndent = `${lastElement?.offsetLeft + lastElement?.offsetWidth + PADDING}px`
      } else {
        textareaRef.current.style.top = `0px`
        textareaRef.current.style.textIndent = `${PADDING}px`
      }
    }, [filters])

    // Adjust the height of the text area
    const resizeTextarea = useCallback(() => {
      if (textareaRef?.current) {
        textareaRef.current.style.height = `${TEXTAREA_HEIGHT}px`

        if (textareaRef.current.offsetHeight < textareaRef.current.scrollHeight) {
          textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
        }
      }
      setHeightToContainer()
    }, [])

    const resizeAndPositionContent = useCallback(() => {
      positionTextarea()
      resizeTextarea()
    }, [positionTextarea, resizeTextarea])

    const debouncedResizeHandler = useDebounceCallback(resizeAndPositionContent, 50, {
      leading: true,
    })

    useEffect(() => {
      window.addEventListener('resize', debouncedResizeHandler)

      return () => {
        window.removeEventListener('resize', debouncedResizeHandler)
      }
    }, [debouncedResizeHandler])

    useEffect(() => {
      debouncedResizeHandler()
    }, [props.searchterm, filters, debouncedResizeHandler])

    useEffect(() => {
      setLocalSearchTerm(props.searchterm)
    }, [props.searchterm])

    useEffect(() => {
      // Cleans all null references
      filterRefs.current = filterRefs.current.slice(0, filters.length)
    }, [filters.length])

    // @ts-expect-error: Muted so we could enable TS strict mode
    const filterpills = filters && (props.sortingFunction ? [...filters].sort(props.sortingFunction) : filters)

    /*Set height to the container so that it accomodates the height of the text area*/
    const setHeightToContainer = () => {
      if (containerRef?.current) {
        containerRef.current.style.height = `${
          // @ts-expect-error: Muted so we could enable TS strict mode
          textareaRef.current.getBoundingClientRect().height + textareaRef.current.offsetTop
        }px`
      }
    }

    const handleTextChange = () => {
      if (props.searchterm !== textareaRef.current?.value) {
        props.onSearchTermChanged(textareaRef.current?.value)
      }
      showSuggestions()
      resizeTextarea()
    }

    const onFocus = () => {
      setIsFocused(true)
      props.onFocusHandler && props.onFocusHandler()
      setIsSuggestionsListVisible(true)
      showSuggestions()
    }

    const onBlur = (event?: FocusEvent<HTMLElement>) => {
      setIsFocused(false)

      if (event) {
        suggestionsDisplay(event)
      }
    }

    const onClearButtonClickHandler = (event: MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      props.clearAllHandler(filters)
    }

    const deleteFilterHandler = (key: number) => {
      props.removeFilter(filters[key] as ContactFilter | CommonRecipientFilter)

      resizeTextarea()
      focusPrevious()
    }

    const toggleSuggestion = (suggestion: AutocompleteSuggestion) => {
      handleTextChange()

      const isSelected = isSuggestionSelected(suggestion)

      // If filter is to be added, remove its name from the search term
      if (!isSelected) {
        // @ts-expect-error: Muted so we could enable TS strict mode
        const cleanedString = getUnmatchedString(suggestion, textareaRef.current.value)
        props.onSearchTermChanged(cleanedString)
      }

      props.onFilterToggled(suggestion as ContactFilter | CommonRecipientFilter)
    }

    const onSuggestionClickHandler = (toBeToggled: AutocompleteSuggestion) => {
      if ((toBeToggled.type === 'email' || toBeToggled.type === 'phoneNumber') && 'entity' in toBeToggled) {
        const splitValues = toBeToggled.entity.value.split(/,? /)

        for (const value of splitValues) {
          props.onFilterAdded?.({
            ...toBeToggled,
            entity: {
              id: value,
              value,
            },
          })
        }
      } else {
        toggleSuggestion(toBeToggled)
      }

      onBlur()
      setActiveSuggestion(null)
    }

    const onSelectAllContentHandler = (e) => {
      e.preventDefault()
      // @ts-expect-error: Muted so we could enable TS strict mode
      newSuggestions?.forEach((contentItem) => props.onFilterAdded(contentItem))
    }

    const suggestionsDisplay = useCallback(
      (event: FocusEvent<HTMLElement>) => {
        if (
          showInlineSuggestions &&
          ((suggestionsRef?.current instanceof HTMLElement &&
            event &&
            suggestionsRef?.current.contains(event.target)) ||
            // @ts-expect-error: Muted so we could enable TS strict mode
            (!!suggestionsRef?.current && event && containerRef?.current.contains(event.target)))
        ) {
          //TODO: This adds an email / phone number filter on blur via clicking anywhere in the body, even if not clicking on the suggestion, is this something we want?
          // autoCompleteSuggestionsHandler()
          setActiveSuggestion(-1)
        } else {
          setIsSuggestionsListVisible(false)
          setIsFocused(false)
        }
      },
      [showInlineSuggestions],
    )

    useEventListener('click', suggestionsDisplay, document.body)

    const onEscapeKeyClicked = () => {
      if (activeSuggestion) {
        setActiveSuggestion(null)
      }

      setIsSuggestionsListVisible(false)
      // @ts-expect-error: Muted so we could enable TS strict mode
      textareaRef.current.blur()
    }

    const focusLastFilter = () => {
      if (filterRefs && filterRefs.current.length > 0) {
        filterRefs.current[filterRefs.current.length - 1].focus()
      }
    }

    const focusNext = () => {
      // @ts-expect-error: Muted so we could enable TS strict mode
      const nextActiveElementIndex = filterRefs.current.indexOf(document.activeElement) + 1
      if (filterRefs.current[nextActiveElementIndex]) {
        filterRefs.current[nextActiveElementIndex].focus()
      } else if (nextActiveElementIndex === filterRefs.current.length) {
        // @ts-expect-error: Muted so we could enable TS strict mode
        textareaRef.current.focus()
      }
    }

    const focusPrevious = () => {
      // @ts-expect-error: Muted so we could enable TS strict mode
      const nextActiveElementIndex = filterRefs.current.indexOf(document.activeElement) - 1
      if (filterRefs.current[nextActiveElementIndex]) {
        filterRefs.current[nextActiveElementIndex].focus()
      }
    }

    const selectPreviousSuggestion = () => {
      if (suggestionsRef.current === null || !showInlineSuggestions || !newSuggestions || newSuggestions.length === 0) {
        return
      }
      // @ts-expect-error: Muted so we could enable TS strict mode
      if (suggestionsRef && suggestionsRef.current.children) {
        let previous = 0
        // @ts-expect-error: Muted so we could enable TS strict mode
        const count = suggestionsRef.current.children.length
        if (activeSuggestion === null) {
          previous = count - 1
          // @ts-expect-error: Muted so we could enable TS strict mode
        } else if (activeSuggestion - 1 < 0) {
          setIsSuggestionsListVisible(false)
        } else {
          // @ts-expect-error: Muted so we could enable TS strict mode
          previous = activeSuggestion - 1
        }
        // @ts-expect-error: Muted so we could enable TS strict mode
        const prevSuggestion = suggestionsRef.current.children[previous]

        if (activeSuggestion === -1 || activeSuggestion === null) {
          // if manual suggestion mode is on, we allow to hide the suggestions manually
          setActiveSuggestion(null)
        } else if (prevSuggestion) {
          prevSuggestion.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          })
          setActiveSuggestion(previous)
        }
      }
    }

    const selectNextSuggestion = () => {
      if (suggestionsRef.current === null || !showInlineSuggestions || !newSuggestions || newSuggestions.length === 0) {
        return
      }

      if (suggestionsRef) {
        setIsSuggestionsListVisible(true)
        let next = 0
        // @ts-expect-error: Muted so we could enable TS strict mode
        if (activeSuggestion !== null && activeSuggestion + 1 <= suggestionsRef.current.children.length - 1) {
          // @ts-expect-error: Muted so we could enable TS strict mode
          next = activeSuggestion + 1
        }
        // @ts-expect-error: Muted so we could enable TS strict mode
        const nextSuggestion = suggestionsRef.current.children[next]
        const nextSuggestionScrollDelta =
          // @ts-expect-error: Muted so we could enable TS strict mode
          nextSuggestion.getBoundingClientRect().bottom - suggestionsWrapperRef.current.getBoundingClientRect().bottom
        if (
          nextSuggestion &&
          // @ts-expect-error: Muted so we could enable TS strict mode
          (nextSuggestionScrollDelta > 0 || activeSuggestion + 1 > suggestionsRef.current.children.length - 1)
        ) {
          // @ts-expect-error: Muted so we could enable TS strict mode
          suggestionsRef.current.children[next].scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          })
        }

        setActiveSuggestion(next)
      }
    }

    const handleInputKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
      const { key } = event
      const { onFilterToggled, triggerSearch, removeFilter } = props

      switch (key) {
        case 'Enter':
          if (!isTagTarget(event.target)) {
            if (activeSuggestion === null || activeSuggestion === -1) {
              triggerSearch && triggerSearch()
            } else {
              toggleSuggestion(getActiveSuggestion())
            }
            setActiveSuggestion(null)
            setIsSuggestionsListVisible(false)
            event.preventDefault()
          }

          return false
        case 'Escape':
          onEscapeKeyClicked()
          event.preventDefault()
          event.stopPropagation()

          return false
        case 'Backspace':
          if (!event.currentTarget.value) {
            filters.length !== 0 && removeFilter(filters[filters.length - 1] as ContactFilter | CommonRecipientFilter)
            // @ts-expect-error: Muted so we could enable TS strict mode
            textareaRef.current.focus()
          }
          break
        case 'ArrowLeft':
          // focus last filter if we're at the 0 position in textarea and the text is not selected
          // @ts-expect-error: Muted so we could enable TS strict mode
          if (textareaRef.current.selectionStart === 0 && textareaRef.current.selectionEnd === 0) {
            focusLastFilter()
            event.preventDefault()

            return false
          }
          break
        case 'Spacebar': // Older browsers use 'Spacebar'
        case ' ':
          if (!showInlineSuggestions) {
            break
          }
          if (activeSuggestion !== null && activeSuggestion !== -1) {
            if (onFilterToggled) {
              onFilterToggled(getActiveSuggestion())
              onBlur()
              setActiveSuggestion(null)
            }
            event.preventDefault()

            return false
          }
          break
        case 'ArrowUp':
          if (!!suggestionsRef?.current && newSuggestions) {
            selectPreviousSuggestion()
          }
          event.preventDefault()

          return false
        case 'ArrowDown':
          if (!suggestionsRef?.current) {
            showSuggestions()
          }
          if (!!suggestionsRef?.current && newSuggestions) {
            selectNextSuggestion()
          }
          event.preventDefault()

          return false
        case 'Tab':
          onBlur()
          setActiveSuggestion(null)
          setIsSuggestionsListVisible(false)

          return false
        default:
          setIsSuggestionsListVisible(true)
      }
    }

    const handleFilterKeyDown = (index: number) => (event: KeyboardEvent<HTMLLIElement>) => {
      const { key } = event
      const { onFilterToggled, triggerSearch, onFilterAdded } = props

      switch (key) {
        case 'Enter':
          if (!isTagTarget(event.target)) {
            if (activeSuggestion === null || activeSuggestion === -1) {
              triggerSearch && triggerSearch()
            } else if (!showInlineSuggestions) {
              // @ts-expect-error: Muted so we could enable TS strict mode
              onFilterAdded(getActiveSuggestion())
            }
            setActiveSuggestion(null)
            event.preventDefault()
          }
          break
        case 'ArrowRight':
          focusNext()
          event.preventDefault()

          return false
        case 'ArrowLeft':
          focusPrevious()
          event.preventDefault()

          return false
        case 'ArrowUp':
          if (!!suggestionsRef?.current && newSuggestions) {
            selectPreviousSuggestion()
          }
          break
        case 'ArrowDown':
          if (!!suggestionsRef?.current && newSuggestions) {
            selectNextSuggestion()
          }
          break
        case 'Escape':
          onEscapeKeyClicked()
          event.preventDefault()
          event.stopPropagation()

          return false
        case 'Spacebar': // Older browsers use 'Spacebar'
        case ' ':
          if (!showInlineSuggestions) {
            break
          }
          if (getActiveSuggestion() && onFilterToggled) {
            onFilterToggled(getActiveSuggestion())
            onBlur()
            setActiveSuggestion(null)
          }
          event.preventDefault()
          break
        case 'Backspace':
          event.preventDefault()
          // @ts-expect-error: Muted so we could enable TS strict mode
          index === 0 && index === filters.length - 1 && textareaRef.current.blur()
          deleteFilterHandler(index)
          break
        case 'Delete':
          // @ts-expect-error: Muted so we could enable TS strict mode
          index === filters.length - 1 && textareaRef.current.blur()
          deleteFilterHandler(index)
          event.preventDefault()
          break
      }
    }

    const handleFilterKeyPress = (filter: AutocompleteFilter) => (event: KeyboardEvent<HTMLLIElement>) => {
      const { key } = event

      switch (key) {
        case '-':
          // @ts-expect-error: Muted so we could enable TS strict mode
          props.excludeHandler(filter)
          event.preventDefault()

          return false
        case '+':
          // @ts-expect-error: Muted so we could enable TS strict mode
          props.includeHandler(filter)
          event.preventDefault()

          return false
        default:
          const isLetter = /^[a-zA-Z]$/.test(key)
          if (isLetter) {
            // @ts-expect-error: Muted so we could enable TS strict mode
            textareaRef.current.focus()
          }
      }
    }

    const getSeparator = (key: number, filter: AutocompleteFilter) => {
      // Filter is last in filters array AND no subsequent searchterm AND searchfield is not focused
      // @ts-expect-error: Muted so we could enable TS strict mode
      const sortedFilters = props.sortingFunction ? [...filters].sort(props.sortingFunction) : filters

      if (key === sortedFilters.length - 1 || (key === sortedFilters.length - 1 && props.searchterm?.length !== 0)) {
        return Separator.last
        // eslint-disable-next-line no-dupe-else-if
      } else if (key === sortedFilters.length - 1 && props.searchterm?.length === 0 && !isFocused) {
        return Separator.none
      } else {
        // TODO: the one instance of typeComparator being used (in <SearchInput />) compares Filter type, but I'm not sure if it's always a Filter type being passed
        // @ts-expect-error: Muted so we could enable TS strict mode
        return props.typeComparator && props.typeComparator(sortedFilters[key + 1], filter)
          ? Separator.category
          : Separator.filter
      }
    }

    // @ts-expect-error: Muted so we could enable TS strict mode
    const getActiveSuggestion = () => newSuggestions[activeSuggestion]

    const showSuggestions = () => {
      if (props.autoSuggestion) {
        setActiveSuggestion(-1)
      } else {
        const nextActiveSuggestionState = activeSuggestion === null ? null : -1
        setActiveSuggestion(nextActiveSuggestionState)
      }
    }

    const isTagTarget = (t) => t.parentElement.className?.includes('tag_filter')

    //#endregion

    const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setLocalSearchTerm(event.target.value)
    }

    useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        handleTextChange()
      }, 50)

      return () => clearTimeout(delayDebounceFn)
    }, [localSearchTerm])

    return (
      <>
        <div
          className={cn('w-full', styles.wrapper, { [styles.error]: !!error })}
          onClick={onFocus}
          onBlur={onBlur}
          ref={ref}
        >
          <div
            className={cn('w-full truncate', styles.inputComponentWrapper, {
              [styles.noBorder]: hasNoBorder,
              [styles.hasFocus]: isFocused || isSuggestionsListVisible,
              [styles.dropdownShown]: isSuggestionsListVisible,
              [styles.error]: !!error,
            })}
          >
            {/* @ts-expect-error: Muted so we could enable TS strict mode */}
            <div className={styles.componentContent} style={{ height: textAreaHeight }} ref={containerRef}>
              <ul className="my-1.5">
                {filterpills?.map((filter, key) => {
                  // TODO: Improve the data structure for filters
                  // currently, it's possible for different types of filters to have no entity key OR have the entity key but the val can be undefined
                  if (!('entity' in filter) || filter.entity !== undefined) {
                    const separator = getSeparator(key, filter)

                    return (
                      <li
                        className={cn(styles.componentItem, {
                          [styles.noSeparator]:
                            !tagSeparator || key + 1 === filters.length || separator === Separator.none,
                        })}
                        key={key}
                        data-key={key}
                        ref={(ref) => {
                          // @ts-expect-error: Muted so we could enable TS strict mode
                          filterRefs.current[key] = ref
                        }}
                        tabIndex={-1}
                        onKeyDown={handleFilterKeyDown(key)}
                        onKeyPress={handleFilterKeyPress(filter)}
                      >
                        <FilterComponent
                          filterKey={key}
                          parentId={props.parentId}
                          hasSeparator={separator !== Separator.none}
                          // TODO: these only handle Filter type, not the other AutocompleteFilter types
                          // @ts-expect-error: Muted so we could enable TS strict mode
                          filter={filter}
                          deleteHandler={() => {
                            deleteFilterHandler(key)
                          }}
                          onChangeFilterDetail={debouncedResizeHandler}
                        />
                        {tagSeparator && <FilterSeparator separator={separator} />}
                      </li>
                    )
                  }
                })}
              </ul>
              <textarea
                className={cn('focus:outline-none', styles.textarea, {
                  [styles.hasFilters]: !!filters?.length,
                })}
                data-gramm="false"
                // @ts-expect-error: Muted so we could enable TS strict mode
                ref={textareaRef}
                onKeyDown={handleInputKeyDown}
                onChange={handleInputChange}
                value={localSearchTerm}
                onFocus={onFocus}
                onBlur={onBlur}
                placeholder={placeholder}
                aria-label={placeholder}
                title={placeholder}
                id={id}
              />
            </div>
            <div className={styles.componentActions}>
              {props.alwaysShowClearAll || props.searchterm || filters.length > 0 ? (
                <Button
                  variant="outline"
                  className="mr-1 h-8 cursor-pointer border-0 p-2 hover:bg-grey.6"
                  name="default"
                  onClick={onClearButtonClickHandler}
                  title={t('Clear')}
                  data-cy="clearContent"
                >
                  <Dismiss24Regular className="h-6 w-6 fill-sky.1" />
                </Button>
              ) : (
                <Button variant="outline" className="border-0 px-3 py-2" name="default" title={t('Search')}>
                  <Search24Regular />
                </Button>
              )}
            </div>
          </div>
          {showInlineSuggestions && newSuggestions.length > 0 && isSuggestionsListVisible && (
            <div
              className={styles.componentSuggestionsWrapper}
              // @ts-expect-error: Muted so we could enable TS strict mode
              ref={suggestionsWrapperRef}
              data-cy="searchSuggestions"
            >
              <ul
                // @ts-expect-error: Muted so we could enable TS strict mode
                ref={suggestionsRef}
                className={cn('overflow-auto', {
                  [styles.maxHeight]: maxHeight,
                })}
              >
                {props.selectAllIcon && (
                  <Button
                    name="default"
                    variant="outline"
                    className="float-right border-0 bg-transparent"
                    onClick={onSelectAllContentHandler}
                    title={t('Select all')}
                  >
                    <Checkmark24Regular />
                  </Button>
                )}
                {newSuggestions.length > 0 &&
                  (props.groupByFolders ? (
                    <GroupedSuggestion
                      // @ts-expect-error: Muted so we could enable TS strict mode
                      activeSuggestion={activeSuggestion}
                      hint={showHint ? props.searchterm : ''}
                      isSuggestionSelected={isSuggestionSelected}
                      onClickHandler={onSuggestionClickHandler}
                      suggestions={newSuggestions as (Profile | Tag)[]}
                    />
                  ) : (
                    <>
                      {newSuggestions.map((suggestion, key) => {
                        const isSelected = isSuggestionSelected(suggestion)

                        return (
                          <li key={key}>
                            <SuggestionComponent
                              //@ts-expect-error: Muted so we could enable TS strict mode
                              suggestion={suggestion}
                              hint={showHint ? props.searchterm : ''}
                              onClickHandler={onSuggestionClickHandler}
                              isSelected={isSelected}
                              focused={key === activeSuggestion}
                            />
                          </li>
                        )
                      })}
                    </>
                  ))}
              </ul>
            </div>
          )}
        </div>
        {error && <div className={styles.errorMsg}>{error}</div>}
      </>
    )
  },
)

AutocompleteField.displayName = 'AutocompleteField'

export default AutocompleteField
